import React from 'react';

const PostIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M27 3.5H5A2.5 2.5 0 0 0 2.5 6v3.5h15l3.4-3.4a3 3 0 0 1 4.83 3.4h3.77V6A2.5 2.5 0 0 0 27 3.5zm-19 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm4 0a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm13 3-6.1 6.06a1.5 1.5 0 0 1-1.06.44h-2.12a1.5 1.5 0 0 1-1.5-1.5v-2.12a1.5 1.5 0 0 1 .44-1.06l1.82-1.82H2.5V26A2.5 2.5 0 0 0 5 28.5h22a2.5 2.5 0 0 0 2.5-2.5V10.5zm-18 5h4a.5.5 0 0 1 0 1H7a.5.5 0 0 1 0-1zm0 4h14a.5.5 0 0 1 0 1H7a.5.5 0 0 1 0-1zm18 5H7a.5.5 0 0 1 0-1h18a.5.5 0 0 1 0 1zm-.59-17.69a2 2 0 0 1 0 2.83l-6.21 6.21a.49.49 0 0 1-.36.15h-2.12a.5.5 0 0 1-.5-.5v-2.12a.47.47 0 0 1 .15-.35l6.22-6.22a2 2 0 0 1 2.82 0z"
          fill="#2b3ea8"
          opacity="1"
        />
      </g>
    </svg>
  );
};

export default PostIcon;
