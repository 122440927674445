import axios from 'axios';

const API_URL = 'https://foodage.co/api';

// Register User
export const register = async (name,email, password) => {
    const response = await axios.post(`${API_URL}/register`, { name,email, password});
    return response.data;
};

// Login User
export const login = async (email, password , device_token) => {
    const response = await axios.post(`${API_URL}/login`, { email, password ,device_token});
    return response.data;
};

// Fetch Protected Data (example)
export const fetchProtected = async (userLoginData) => {
    const response = await axios.get(`${API_URL}`, {
        headers: {
            Authorization: `Bearer ${userLoginData.auth_token}`
        }
    });
    return response.data;
};

export const fetchNewsfeedPosts = async (userLoginData) => {
    try {
        const response = await axios.get(`${API_URL}/newsfeed_posts`, {
            headers: {
                Authorization: `Bearer ${userLoginData.auth_token}`, // Ensure the format is correct
            },
        });
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error fetching newsfeed posts:', error.response ? error.response.data : error.message);
        throw error; // Re-throw the error for handling elsewhere
    }
};