import React from 'react';

const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M16 1a15 15 0 1 0 15 15A15.018 15.018 0 0 0 16 1zm-1 7a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0zm1 17a9.014 9.014 0 0 1-9-9 8.92 8.92 0 0 1 3.38-7.02 1 1 0 1 1 1.24 1.56 7 7 0 1 0 8.76 0 1 1 0 0 1 1.24-1.56A8.92 8.92 0 0 1 25 16a9.014 9.014 0 0 1-9 9z"
          fill="#2b3ea8"
          opacity="1"
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
