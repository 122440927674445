import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';  // Import js-cookie

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Check if the user is authenticated
    useEffect(() => {
        const userLoginData = Cookies.get('userLoginData');  // Get the user data from cookies
        if (userLoginData) {
            setUser(JSON.parse(userLoginData));  // Parse the JSON string from cookies
        }
        setLoading(false);
    }, []);

    const login = (userLoginData) => {
        // Set the user data in cookies with an expiration of 1 days (for example)
        Cookies.set('userLoginData', JSON.stringify(userLoginData), { expires: 1 ,secure: true, sameSite: 'strict' });
        setUser(userLoginData);  // Update the state with the user data
    };

    const logout = () => {
        // Remove the user data from cookies and state
        Cookies.remove('userLoginData');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
