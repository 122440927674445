import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '../../../assets/icons/LogoutIcon';

const Logout = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return <button onClick={handleLogout} className='hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium w-full'>
        <LogoutIcon/>
           <span className='p'> Logout</span>
        </button>;
};

export default Logout;