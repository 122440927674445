import React from 'react';

const SidebarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <circle cx="20.5" cy="6.5" r="1.5" fill="#1363df" opacity="1" />
        <path fill="#2b3ea8" d="M16.5 8h-13a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 0 3z" opacity="1" />
        <g fill="#2b3ea8">
          <path
            d="M16.5 14h-13a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 0 3zM10.5 20h-7a1.5 1.5 0 0 1 0-3h7a1.5 1.5 0 0 1 0 3z"
            fill="#2b3ea8"
            opacity="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default SidebarIcon;
