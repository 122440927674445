import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { register } from '../../../Api/auth';
import images from '../../../assets/images/images';
import GoogleIcon from '../../../assets/icons/googleIcon';
import AppIcon from '../../../assets/icons/appIcon';

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const Signup = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await register(values.name,values.email, values.password );
      const userLoginData = response.data || {};
      if (!userLoginData) {
        console.error('User registration data is undefined');
        return;
      }
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration failed', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='container px-52 min-h-screen'>
      <div className="grid grid-cols-2 py-5 min-h-screen">
        {/* Left Side - Images */}
        <div className="pe-10 my-auto lg:d-block md:d-none">
          <div className="relative w-[500px]">
            <img 
              src={images.login2}
              alt="Left Phone"
              className="w-[240px] h-[490px] rounded-2xl shadow-lg"
            />
            <img 
              src={images.login1}
              alt="Right Phone"
              className="w-[245px] rounded-2xl shadow-lg h-[490px] absolute top-4 left-24"
            />
          </div>
        </div>
        {/* Right Side - Signup Form */}
        <div className="bg-secondary px-8 my-auto pt-4 pb-8 rounded-lg shadow-sm border">
          <div className='flex justify-center mb-6'>
            <img src={images.logo} className='h-32 w-32' />
          </div>

          <Formik
            initialValues={{ name: '', email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="custom-input"
                  />
                  <ErrorMessage name="name" component="div" className="text-primary text-sm" />
                </div>
                <div className="mb-4">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="custom-input"
                  />
                  <ErrorMessage name="email" component="div" className="text-primary text-sm" />
                </div>
                <div className="mb-4">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="custom-input"
                  />
                  <ErrorMessage name="password" component="div" className="text-primary text-sm" />
                </div>
                <button
                  type="submit"
                  className="primary-button mt-6"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Signing up...' : 'Sign Up'}
                </button>
              </Form>
            )}
          </Formik>

          <div className="flex mt-4 justify-between">
            <p className="text-sm text-gray-600 font-medium">Already have an account? <Link to="/login" className="text-primary underline">Sign In</Link></p>
          </div>

          <div className="text-center mt-10">
            <p className="text-sm text-secondary-light font-semibold mb-3">Get the app.</p>
            <div className="flex justify-center space-x-4 mt-2">
              <a href='https://play.google.com/store/apps/details?id=com.digixvalley.foodage' className="bg-secondary-dark text-secondary px-2 py-1 rounded-md">
                <div className='flex gap-2'>
                  <div className='pt-2'>
                    <GoogleIcon />
                  </div>
                  <div className='text-left'>
                    <p className='text-xs m-0 p-0' style={{ fontSize: "10px" }}>Get it on</p>
                    <p className='text-sm m-0 p-0'>Google Play</p>
                  </div>
                </div>
              </a>
              <a href='https://apps.apple.com/us/app/foodage-find-food-reviews/id1620709672' className="bg-secondary-dark text-secondary px-2 py-1 rounded-md">
                <div className='flex gap-2'>
                  <div className='pt-2'>
                    <AppIcon />
                  </div>
                  <div className='text-left'>
                    <p className='text-xs m-0 p-0' style={{ fontSize: "10px" }}>Get it on</p>
                    <p className='text-sm m-0 p-0'>Apple Store</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
