import React from 'react';

const MessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M354.03 31.622H57.9c-31.93 0-57.9 25.97-57.9 57.9v259.5c0 12.29 13.899 19.218 23.71 12.21l82.47-58.84c6.92-4.93 15.06-7.54 23.56-7.54h181.39c31.93 0 57.9-25.97 57.9-57.9V46.622c0-8.28-6.72-15-15-15zm-72.86 181.71H107.86c-8.28 0-15-6.71-15-15 0-8.28 6.72-15 15-15h173.31c8.28 0 15 6.72 15 15 0 8.29-6.72 15-15 15zm0-70H107.86c-8.28 0-15-6.71-15-15 0-8.28 6.72-15 15-15h173.31c8.28 0 15 6.72 15 15 0 8.29-6.72 15-15 15z"
          fill="#2b3ea8"
          opacity="1"
        />
        <path
          d="M512 205.872v259.49c0 12.207-13.829 19.268-23.71 12.21l-82.47-58.83c-6.92-4.93-15.06-7.54-23.56-7.54H200.87c-31.93 0-57.9-25.98-57.9-57.91v-28.44h168.16c48.47 0 87.9-39.43 87.9-87.9v-88.99h55.07c31.93 0 57.9 25.98 57.9 57.91z"
          fill="#2b3ea8"
          opacity="1"
        />
      </g>
    </svg>
  );
};

export default MessageIcon;
