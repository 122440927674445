import React from "react";
import images from "../../assets/images/images";
const Topbar = ()=>{
    return(
        <div className="container ">
            <div className="bg-white shadow-md px-8 border-b flex justify-between items-center py-3 w-full">
            <h1 className="font-semibold  text-lg">Home</h1>
            <div className="flex items-center space-x-2">
               <img src={images.noImage} className="h-12 w-12 rounded-full shadow-md"/>
               <div>
                <p className="font-semibold m-0 text-sm">Username@</p>
                <p className="text-secondary-light text-sm">name</p>
               </div>
            </div>
        </div>
        </div>
    )
}
export default Topbar