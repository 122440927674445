import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './presentation/pages/auth/login';
// import Sidebar from './presentation/components/Sidebar';
// import Topbar from './presentation/components/Topbar';
// import FollowAccount from './presentation/components/FollowAccount';
// import FollowCard from './presentation/components/FollowCard';
// import InstagramPost from './presentation/components/InstagramPost';
import ProtectedRoute from './presentation/pages/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import Signup from './presentation/pages/auth/Signup';
import Dashboard from './presentation/pages/Home/Dashboard';
const App = ()=>{
  return (
    <div className='container mx-auto '>
          {/* <Login/> */}
          <AuthProvider>
                
                <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Signup />} />
                    <Route path="/dashboard" element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } />
                </Routes>
                </Router>
              </AuthProvider>
     
    </div>
  )
}
export default App