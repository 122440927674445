import FollowCard from "../../components/FollowCard"
import Sidebar from "../../components/Sidebar"
import Topbar from "../../components/Topbar"
const Dashboard =()=>{
 return(
    <div className='flex relative w-full'>
    <div className='min-h-screen '>
      <div className=''>
          <Sidebar/>
      </div>
    </div>
      <div className='w-full '>
        <Topbar/>
         <FollowCard/>
      </div>
  </div>
 )
}

export default Dashboard