import React from 'react';

const ExploreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 426.667 426.667"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M213.333 236.8c13.013 0 23.467-10.56 23.467-23.467s-10.453-23.467-23.467-23.467c-12.907 0-23.467 10.56-23.467 23.467s10.561 23.467 23.467 23.467z"
          fill="#2b3ea8"
          opacity="1"
        />
        <path
          d="M213.333 0C95.467 0 0 95.467 0 213.333c0 117.76 95.467 213.333 213.333 213.333s213.333-95.573 213.333-213.333C426.667 95.467 331.2 0 213.333 0zm46.72 260.053-174.72 81.28 81.28-174.72 174.72-81.28-81.28 174.72z"
          fill="#2b3ea8"
          opacity="1"
        />
      </g>
    </svg>
  );
};

export default ExploreIcon;
