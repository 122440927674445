import React from 'react';

const MapIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M21.386 10C20.331 5.1 18.081 2 16 2s-4.331 3.1-5.386 8zM10 16a30.013 30.013 0 0 0 .267 4h11.466A30.013 30.013 0 0 0 22 16a30.013 30.013 0 0 0-.267-4H10.267A30.013 30.013 0 0 0 10 16zM10.614 22c1.055 4.9 3.305 8 5.386 8s4.331-3.1 5.386-8zM23.434 10h6.3a15.058 15.058 0 0 0-10.449-8.626C21.182 3.043 22.67 6.129 23.434 10zM30.453 12h-6.7A32.332 32.332 0 0 1 24 16a32.332 32.332 0 0 1-.248 4h6.7a14.9 14.9 0 0 0 0-8zM19.285 30.626A15.058 15.058 0 0 0 29.736 22h-6.3c-.766 3.871-2.254 6.957-4.151 8.626zM8.566 22h-6.3a15.058 15.058 0 0 0 10.451 8.626C10.818 28.957 9.33 25.871 8.566 22zM12.715 1.374A15.058 15.058 0 0 0 2.264 10h6.3c.766-3.871 2.254-6.957 4.151-8.626zM8 16a32.332 32.332 0 0 1 .248-4h-6.7a14.9 14.9 0 0 0 0 8h6.7A32.332 32.332 0 0 1 8 16z"
          fill="#2b3ea8"
          opacity="1"
        />
      </g>
    </svg>
  );
};

export default MapIcon;
