import React from 'react';

const EmojiGroupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="90"
      height="90"
      x="0"
      y="0"
      viewBox="0 0 420.495 420"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M90.73 243.297c-.68 0-1.37-.102-2.054-.32C36.465 226.547 0 176.129 0 120.367 0 53.453 54.32 1.031 123.664 1.031c34.297 0 65.988 12.715 89.242 35.805a6.824 6.824 0 0 1 .035 9.652 6.82 6.82 0 0 1-9.652.035c-20.68-20.53-48.957-31.836-79.625-31.836-61.687 0-110.012 46.418-110.012 105.684 0 49.863 32.535 94.926 79.125 109.586a6.829 6.829 0 0 1-2.047 13.34zm0 0"
          fill="#082e97"
          opacity="1"
        ></path>
        <path
          d="M191.93 83.734c0 7.543-6.114 13.657-13.653 13.657-7.543 0-13.652-6.114-13.652-13.657 0-7.539 6.11-13.652 13.652-13.652 7.54 0 13.653 6.113 13.653 13.652zM82.703 83.734c0 7.543-6.113 13.657-13.652 13.657-7.543 0-13.653-6.114-13.653-13.657 0-7.539 6.11-13.652 13.653-13.652 7.539 0 13.652 6.113 13.652 13.652zm0 0"
          fill="#082e97"
          opacity="1"
        ></path>
        <path
          d="M327.168 243.293a6.824 6.824 0 0 1-6.555-4.938 6.826 6.826 0 0 1 4.668-8.449c47.262-13.61 81.563-60.008 81.563-110.32 0-59.262-48.324-105.684-110.012-105.684S186.82 60.324 186.82 119.586c0 3.281.145 6.547.426 9.777a6.827 6.827 0 0 1-6.21 7.39 6.792 6.792 0 0 1-7.391-6.21 127.307 127.307 0 0 1-.477-10.957c0-66.918 54.32-119.34 123.664-119.34s123.664 52.422 123.664 119.34c0 56.266-38.453 108.184-91.433 123.437a6.769 6.769 0 0 1-1.895.27zm0 0"
          fill="#082e97"
          opacity="1"
        ></path>
        <path
          d="M208.086 370.457c-67.035 0-123.664-58.613-123.664-127.992 0-66.918 54.32-119.336 123.664-119.336S331.75 175.547 331.75 242.465c0 69.379-56.629 127.992-123.664 127.992zm0-233.676c-61.688 0-110.012 46.422-110.012 105.684 0 61.98 50.383 114.34 110.012 114.34s110.008-52.36 110.008-114.34c0-59.262-48.32-105.684-110.008-105.684zm0 0"
          fill="#082e97"
          opacity="1"
        ></path>
        <path
          d="M339.781 178.527a6.836 6.836 0 0 1-6.207-3.976c-6.664-14.508-21.285-23.883-37.238-23.883-5.93 0-11.648 1.238-16.992 3.68a6.828 6.828 0 0 1-9.047-3.371 6.823 6.823 0 0 1 3.371-9.047c7.137-3.258 14.766-4.914 22.668-4.914 21.273 0 40.762 12.496 49.644 31.832a6.828 6.828 0 0 1-6.199 9.68zM365.098 82.953c0 7.54-6.114 13.652-13.653 13.652-7.539 0-13.652-6.113-13.652-13.652s6.113-13.652 13.652-13.652c7.54 0 13.653 6.113 13.653 13.652zM255.871 82.953c0 7.54-6.113 13.652-13.652 13.652s-13.653-6.113-13.653-13.652 6.114-13.652 13.653-13.652 13.652 6.113 13.652 13.652zM149.477 144.715c-5.278-12.414-15.258-20.8-26.723-20.8-16.965 0-30.719 18.339-30.719 40.956 0 11.844 3.79 22.473 9.809 29.953 10.078-21.394 26.793-38.75 47.633-50.11zM208.086 321.887c-21.274 0-40.766-12.496-49.649-31.832a6.826 6.826 0 0 1 3.356-9.055c3.414-1.574 7.477-.074 9.055 3.352 6.66 14.507 21.28 23.882 37.238 23.882 15.773 0 29.879-8.808 36.812-22.984a6.83 6.83 0 0 1 9.133-3.133c3.387 1.66 4.793 5.746 3.133 9.133-9.246 18.898-28.05 30.637-49.078 30.637zM276.352 205.832c0 7.543-6.114 13.652-13.653 13.652-7.543 0-13.652-6.109-13.652-13.652 0-7.539 6.11-13.652 13.652-13.652 7.54 0 13.653 6.113 13.653 13.652zM167.125 205.832c0 7.543-6.113 13.652-13.652 13.652-7.543 0-13.653-6.109-13.653-13.652 0-7.539 6.11-13.652 13.653-13.652 7.539 0 13.652 6.113 13.652 13.652zm0 0"
          fill="#082e97"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
};

export default EmojiGroupIcon;
