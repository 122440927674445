import React from 'react';

const googleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="22"
            height="22"
            x="0"
            y="0"
            viewBox="0 0 512 512"
            style={{ enableBackground: 'new 0 0 512 512' }}
            xmlSpace="preserve"
            className=""
        >
            <g>
                <linearGradient id="a" x1="261.613" x2="-72.457" y1="8802.763" y2="8468.693" gradientTransform="matrix(1 0 0 -1 0 8834)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style={{ stopColor: '#00A0FF' }} />
                    <stop offset=".007" style={{ stopColor: '#00A2FF' }} />
                    <stop offset=".26" style={{ stopColor: '#00BEFF' }} />
                    <stop offset=".512" style={{ stopColor: '#00D2FF' }} />
                    <stop offset=".76" style={{ stopColor: '#00DFFF' }} />
                    <stop offset="1" style={{ stopColor: '#00E3FF' }} />
                </linearGradient>
                <path d="M35.395 7.913c-5.789 6.127-9.212 15.64-9.212 27.966v440.242c0 12.336 3.424 21.839 9.212 27.966l1.473 1.435 246.615-246.613v-5.818L36.868 6.468l-1.473 1.445z" style={{ fill: 'url(#a)' }} />
                
                <linearGradient id="b" x1="501.174" x2="19.512" y1="8577.99" y2="8577.99" gradientTransform="matrix(1 0 0 -1 0 8834)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style={{ stopColor: '#FFE000' }} />
                    <stop offset=".409" style={{ stopColor: '#FFBD00' }} />
                    <stop offset=".775" style={{ stopColor: '#FFA600' }} />
                    <stop offset="1" style={{ stopColor: '#FF9C00' }} />
                </linearGradient>
                <path d="m365.683 341.149-82.2-82.24v-5.818l82.219-82.219 1.853 1.057 97.396 55.341c27.821 15.805 27.821 41.667 0 57.482l-97.396 55.341-1.872 1.056z" style={{ fill: 'url(#b)' }} />
                
                <linearGradient id="c" x1="321.873" x2="-131.153" y1="8532.313" y2="8079.288" gradientTransform="matrix(1 0 0 -1 0 8834)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style={{ stopColor: '#FF3743' }} />
                    <stop offset="1" style={{ stopColor: '#E30864' }} />
                </linearGradient>
                <path d="m367.556 340.082-84.073-84.081L35.395 504.087c9.173 9.708 24.309 10.91 41.367 1.222l290.794-165.227" style={{ fill: 'url(#c)' }} />
                
                <linearGradient id="d" x1="-27.071" x2="175.224" y1="8972.631" y2="8770.336" gradientTransform="matrix(1 0 0 -1 0 8834)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style={{ stopColor: '#00A06F' }} />
                    <stop offset=".069" style={{ stopColor: '#00A86E' }} />
                    <stop offset=".476" style={{ stopColor: '#00CF6E' }} />
                    <stop offset=".801" style={{ stopColor: '#00E76D' }} />
                    <stop offset="1" style={{ stopColor: '#00EF6D' }} />
                </linearGradient>
                <path d="M367.556 171.918 76.762 6.691C59.704-2.997 44.568-1.795 35.395 7.913l248.088 248.088 84.073-84.083z" style={{ fill: 'url(#d)' }} />
            </g>
        </svg>
    );
};

export default googleIcon;
