import logo from './logo.png';
import login1 from './login1.PNG';
import login2 from './login2.png';
import noImage from './noImage.png'
export default {
    logo,
    login1,
    login2,
    noImage


}