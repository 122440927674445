import React, { useState,useEffect,useContext } from 'react';
import OfficialIcon from '../../assets/icons/Officialcon';
import EmojiGroupIcon from '../../assets/icons/EmojiGroupIcon';
import { fetchNewsfeedPosts } from '../../Api/auth'; // Import the API function
import { AuthContext } from '../../context/AuthContext';  // Path to your AuthContext
import images from '../../assets/images/images';

const accounts = [
  { id: 1, name: 'V', username: 'thv', description: 'Popular', checked: true },
  { id: 2, name: 'Talha Jahanzeb', username: 'default.xd', description: 'Suggested for you', checked: true },
  { id: 3, name: 'humtypakistan', username: 'humtypakistanofficial', description: 'Popular', checked: true },
  { id: 4, name: 'Hamza Ali Abbasi', username: 'realhamzaaliabbasi', description: 'Popular', checked: true },
  { id: 5, name: 'Merium Pervaiz', username: 'merium.pervaiz', description: 'Popular', checked: true },
  { id: 6, name: 'Disha Patani', username: 'dishapatani', description: 'Popular', checked: false },
  { id: 7, name: 'Aroob Jatoi', username: 'aroob.jatoi', description: 'Popular', checked: false },
  { id: 8, name: 'Kanwal Aftab', username: 'kanwal.135', description: 'Popular', checked: false },
  { id: 9, name: 'Atif Aslam', username: 'atifaslam', description: 'Popular', checked: false },
];

function FollowCard() {
  const [accountList, setAccountList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch accounts data from the API on component mount
  const { user } = useContext(AuthContext);  // Access user data from AuthContext

    const fetchNewsfeed = async () => {
        if (user && user.auth_token) {  // Check if the user is logged in and has a token
            try {
                const newsfeedData = await fetchNewsfeedPosts(user);
                setAccountList(newsfeedData.data.for_new_users);
            } catch (error) {
                console.error('Failed to fetch newsfeed:', error);
            }
        } else {
            console.error('User not authenticated');
        }
    };

    useEffect(() => {
        fetchNewsfeed();  // Fetch newsfeed when the component mounts
    }, []);


  // const toggleCheckbox = (id) => {
  //   setAccountList(
  //     accountList.map(account =>
  //       account.id === id ? { ...account, checked: !account.checked } : account
  //     )
  //   );
  // };
  return (
    <div className=''>
        <div className="bg-secondary min-h-screen text-secondary-dark p-4 col-span-4 ">
            <div className="flex justify-between items-center mb-6 border-b border-secondary-light pb-8 gap-8">
                <div>
                    <EmojiGroupIcon/>
                </div>
                <div className='flex w-full justify-between gap-8'>
                    <div>
                        <h1 className="text-lg font-bold">Find friends and accounts you like</h1>
                        <p className='text-secondary-light text-xs font-medium'>Try following 5 or more account for personalized experience.</p>
                    </div>
                    <div>
                        <button className="bg-primary mt-4 hover:bg-blue-500 px-20 text-secondary font-semibold py-2  rounded text-sm">
                        Next
                        </button>
                    </div>
                </div>
               
            </div>

            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="custom-input "
            />

            <div className="grid grid-cols-4 gap-6 mt-8">
                {accountList
                .filter(account =>
                    account.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map(account => (
                    <div key={account.id} className=" p-3 shadow rounded-lg">
                    <div className=" flex flex-col items-center pt-4 ">
                    {account.image ? (
                          <img
                              src={account.image}
                              alt={account.name}
                              className="h-16 w-16 rounded-full mb-4"
                          />
                      ) : (
                          <img
                              src={images.noImage} // Replace with your placeholder image path
                              alt="Placeholder"
                              className="h-16 w-16 rounded-full mb-4"
                          />
)}
                        <div className="ml-3 text-center">
                        <p className="text-secondary-dark font-medium text-sm text-center flex justify-center">{account.name} <span className=' px-2'><OfficialIcon/></span></p>
                        <p className="text-gray-400 text-xs">{account.email}</p>
                        </div>
                    </div>
                    <button className='primary-button mt-10'>Follow</button>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
}

export default FollowCard;
