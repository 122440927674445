import { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login as loginUser } from '../../../Api/auth';
import { AuthContext } from '../../../context/AuthContext';
import images from '../../../assets/images/images';
import GoogleIcon from '../../../assets/icons/googleIcon';
import AppIcon from '../../../assets/icons/appIcon';

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const device_token = "f0zMgUpXRAeoGQqu1IIi-s:APA91bGykOYE9Nfibs3cuNQ1jAYBrpOeR-hJp-yHwg7SXtnTdhsh6lq0MnPFZNhxXqteao5wVjH321I-4ZqPi9T0ejQJLa0n6Yuu7IuLdcTQcRM-Yep_sXoaeS38fyXlqF2GkVZbmLqW";

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await loginUser(values.email, values.password, device_token);
      const userLoginData = response.data || {};
      if (!userLoginData) {
        console.error('User login data is undefined');
        return;
      }
      login(userLoginData);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='container px-52 min-h-screen'>
      <div className="grid grid-cols-2 py-5 min-h-screen">
        {/* Left Side - Chat UI */}
        <div className="pe-10 my-auto lg:d-block md:d-none">
          <div className="relative w-[500px]">
            <img
              src={images.login2}
              alt="Left Phone"
              className="w-[240px] h-[490px] rounded-2xl shadow-lg"
            />
            <img
              src={images.login1}
              alt="Right Phone"
              className="w-[245px] rounded-2xl shadow-lg h-[490px] absolute top-4 left-24"
            />
          </div>
        </div>
        {/* Right Side - Login Form */}
        <div className="bg-secondary px-8 my-auto pt-4 pb-8 rounded-lg shadow-sm border">
          <div className='flex justify-center mb-6'>
            <img src={images.logo} className='h-32 w-32' />
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Phone number, username, or email"
                    className="custom-input"
                  />
                  <ErrorMessage name="email" component="div" className="text-primary text-sm" />
                </div>
                <div className="mb-4">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="custom-input"
                  />
                  <ErrorMessage name="password" component="div" className="text-primary text-sm" />
                </div>
                <button
                  type="submit"
                  className="primary-button mt-6"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Logging in...' : 'Log in'}
                </button>
              </Form>
            )}
          </Formik>

          <div className="flex mt-4 justify-between">
            <p className="text-sm text-gray-600 font-medium">Don't have an account? <Link to="/register" className="text-primary underline">Sign up</Link></p>
            <p className="text-xs text-secondary-light font-medium mt-1 text-right underline hover:text-primary hover:cursor-pointer">
              Forgot password?
            </p>
          </div>

          <div className="text-center mt-10">
            <p className="text-sm text-secondary-light font-semibold mb-3">Get the app.</p>
            <div className="flex justify-center space-x-4 mt-2">
              <a href='https://play.google.com/store/apps/details?id=com.digixvalley.foodage' className="bg-secondary-dark text-secondary px-2 py-1 rounded-md">
                <div className='flex gap-2'>
                  <div className='pt-2'>
                    <GoogleIcon />
                  </div>
                  <div className='text-left'>
                    <p className='text-xs m-0 p-0' style={{ fontSize: "10px" }}>Get it on</p>
                    <p className='text-sm m-0 p-0'>Google Play</p>
                  </div>
                </div>
              </a>
              <a href='https://apps.apple.com/us/app/foodage-find-food-reviews/id1620709672' className="bg-secondary-dark text-secondary px-2 py-1 rounded-md">
                <div className='flex gap-2'>
                  <div className='pt-2'>
                    <AppIcon />
                  </div>
                  <div className='text-left'>
                    <p className='text-xs m-0 p-0' style={{ fontSize: "10px" }}>Get it on</p>
                    <p className='text-sm m-0 p-0'>Apple Store</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
