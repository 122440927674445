import React from 'react';

const appIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="22"
            height="22"
            x="0"
            y="0"
            viewBox="0 0 512.003 512.003"
            style={{ enableBackground: 'new 0 0 512 512' }}
            xmlSpace="preserve"
            className=""
        >
            <g>
                <path
                    d="M351.98 0c-27.296 1.888-59.2 19.36-77.792 42.112-16.96 20.64-30.912 51.296-25.472 81.088 29.824.928 60.64-16.96 78.496-40.096 16.704-21.536 29.344-52 24.768-83.104z"
                    fill="#CCD0D2"
                    opacity="1"
                    data-original="#CCD0D2"
                ></path>
                <path
                    d="M459.852 171.776c-26.208-32.864-63.04-51.936-97.824-51.936-45.92 0-65.344 21.984-97.248 21.984-32.896 0-57.888-21.92-97.6-21.92-39.008 0-80.544 23.84-106.88 64.608-37.024 57.408-30.688 165.344 29.312 257.28 21.472 32.896 50.144 69.888 87.648 70.208 33.376.32 42.784-21.408 88-21.632 45.216-.256 53.792 21.92 87.104 21.568 37.536-.288 67.776-41.28 89.248-74.176 15.392-23.584 21.12-35.456 33.056-62.08-86.816-33.056-100.736-156.512-14.816-203.904z"
                    fill="#CCD0D2"
                    opacity="1"
                    data-original="#CCD0D2"
                ></path>
            </g>
        </svg>
    );
};

export default appIcon;
