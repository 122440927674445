import React, { useState } from 'react';
import SidebarIcon from '../../assets/icons/SidebarIcon';
import images from '../../assets/images/images';
import MoreIcon from '../../assets/icons/MoreIcon';
import HomeIcon from '../../assets/icons/HomeIcon';
import MapIcon from '../../assets/icons/MapIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import ExploreIcon from '../../assets/icons/ExploreIcon';
import PostIcon from '../../assets/icons/PostIcon';
import NotificationIcon from '../../assets/icons/NotificationIcon';
import Logout from '../pages/auth/logout';
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true); // Sidebar is open initially

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the open/close state
  };

  return (
   <div className={`${isOpen ? 'w-64 relative transition-all duration-300':'w-10 transition-all duration-300'}`}>
     <div
      className={`bg-secondary text-primary flex flex-col min-h-screen border fixed transition-all duration-300 ${
        isOpen ? 'w-64' : 'w-10 '
      }`}
    >
      <h1 className="font-bold px-4">
        {isOpen && <img src={images.logo} className="h-32 w-32" alt="Logo" />}
      </h1>
      <button
        className="border bg-secondary absolute px-2 py-2 rounded-full right-[-20px] top-10"
        onClick={toggleSidebar}
      >
        <SidebarIcon />
      </button>
      <nav className="flex-1 px-3 overflow-hidden">
      {isOpen && 
        <ul className="space-y-2">
        <li>
          <a
            href="#"
            className=" flex gap-4 hover:bg-primary-light  py-3 px-3  rounded-lg font-medium"
          >
            <HomeIcon/>
           <span className='pt-1'>Home</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className=" hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium"
          >
            <MapIcon/>
            <span className=''>Map</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className=" hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium"
          >
            <ExploreIcon/>
            <span className=''>Explore</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className=" hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium"
          >
            <PostIcon/>
            <span className=''>Create Post</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className=" hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium"
          >
            <MessageIcon/>
            <span className=''>Message</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className=" hover:bg-primary-light   py-3 px-3 flex gap-5 rounded-lg font-medium"
          >
            <NotificationIcon/>
            <span className='pt-1'>Noticification</span>
          </a>
        </li>
        <li>
          <a>
          <Logout/>
          </a>
          
        </li>
        </ul>
      }
      </nav>
      <nav className='mb-4 px-3'>
        {isOpen && 
          <ul>
            <li className='flex gap-4 hover:bg-primary-light  py-3 px-3  rounded-lg font-medium'>
                <MoreIcon/>
              <span> More</span>
            </li>
          </ul> 
        }
      </nav>
    </div>
   </div>
  );
};

export default Sidebar;
